body {
  margin: 0;
  height: 100vh;
}

#root,
main {
  height: 100%;
}

.highlighted-row {
  background-color: #e6f7ff;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #c7c7c7 #ededed;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ededed;
}

*::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 0px;
}

.ant-table-wrapper .ant-table-column-title {
  z-index: 0;
}
